import React, { useState, useEffect } from "react";

// Libs
import moment from "moment";
import { FiExternalLink } from "react-icons/fi";

// Imgs
import DefaultUser from "assets/default-user.png";

// Components
import KonvaImagePoints from "../../../../../../components/KonvaImagePoints";
import ResultText from "../../components/ResultText";
import ModalAlert from "components/ModalAlert";

// Context
import { useGeneralProvider } from "contexts/general";

// Services
import DashboardService from "services/dashboard";

// Constants
import { reportStatusENUM } from "../../../../../../constants";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
  minTemperatureCelsius,
  maxTemperatureCapCelsius
} from "../../../../../../components/ThermalManipulation/constants";

import {
  DataContainer,
  HeaderTextContainer,
  HeaderDivider,
  HeaderTitle,
  HeaderSubtitle,
  BodyHeaderText,
  InfoCardContainer,
  EmptyContainer,
  ContentContainer,
  ImageCaptureSection,
  ImageCaptureContainer,
  ImageCapture,
  ImageCaptureInfoContainer,
  GeneralInformationBody,
  GeneralInformationRow,
  GeneralInformationGroup,
  GeneralInformationGroupContent,
  GroupDataContainer,
  ItemText,
} from "./styles";
import FormSection from "./components/FormSection/index";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function BodySection({
  generalData,
  selectedItem,
  handleSeeGroupByID,
  setShowModalGenerateReport,
}) {
  const palleteRgb = getDefaultPalleteRgb();
  const { globalUser } = useGeneralProvider();
  const userPassService = new DashboardService(globalUser);  

  const [showModalAlert, setShowModalAlert] = useState(false);

  const [metadataImageUrl, setMetadataImageUrl] = useState('');
  const [metadataColor, setMetadataColor] = useState('');
  const [metadataPointsArray, setMetadataPointsArray] = useState([]);

  useEffect(() => {
    //metadata image
    if(generalData) {
      generalData?.form?.map(item => {
        if (item.name === 'visualDiagramMetadataImageURL') {
          setMetadataImageUrl(item.stringValue);
        } 
        if (item.name === 'visualDiagramMetadataMarkdownColor') {
          setMetadataColor(item.stringValue);
        }
        if (item.name === 'visualDiagramMetadataPointsArray') {
          setMetadataPointsArray(JSON.parse(item.stringValue));
        }
      })
    }
  }, [generalData]);

  const handleReportClick = ({ reportStatus, link, update }) => {
    if (reportStatus === 3) {
      return;
    }

    if (reportStatus === 1) {
      window.open(link, "_blank");
      return;
    }

    if (reportStatus === 2) {
      if (update) {
        setShowModalAlert(true);
      }
      setShowModalGenerateReport(true);
      return;
    }
  };

  // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
  const handleGetTemperature = (green, blue) => {
      const raw = green * 256 + blue;

      const M = 0.02569698246480104;
      const B = 64.74694198003411;

      const temp = M * raw - B;

      return temp;
  };

  const handleInitialMapping = (imageData) => {
      const dataLength = imageData.data.length;
      const pixelLength = 4;

      const temperaturePixelArray = [];

      for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
        const green = imageData.data[i + 1];
        const blue = imageData.data[i + 2];

        const temp = handleGetTemperature(green, blue);

        if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
          temperaturePixelArray.push({ t: temp, p: i });
        }
      }

      const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
        if (a.t < b.t) return -1;
        if (a.t > b.t) return 1;
        return 0;
      });

      return currentImageSortedData;
  };

  const handleTemperaturePerPixel = (imageData, currentImageValues) => {
      const temperaturePixelArray = [];

      for (let i = 0; i < currentImageValues.length; i++) {
        temperaturePixelArray.push(currentImageValues[i]);
      }
      return temperaturePixelArray;
  };

  const handlePixelTransform = (imageData, currentImageValues) => {
      const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
      const expandedPalette = [];

      const pixelsPerColor = Math.ceil(
        temperaturePixelArray.length / palleteRgb.length
      );

      const reversedPalette = [...palleteRgb].reverse();

      for (let i = 0; i < reversedPalette.length; i++) {
        for (let j = 0; j < pixelsPerColor; j++) {
            expandedPalette.push(reversedPalette[i]);
        }
      }

      if (expandedPalette.length !== 0) {
        for (let i = 0; i < temperaturePixelArray.length; i++) {
            const currentColor = expandedPalette[i] || [0, 0, 0];

            // red
            imageData.data[temperaturePixelArray[i].p] = currentColor[0];
            // green
            imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
            // blue
            imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
        }
      }
      return imageData;
  };

  const validURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
  }

  const getTransformedImage = async (imageUrl) => {    
      let reload = false;
      if (validURL(imageUrl.target.currentSrc)) {
        const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
        imageUrl.target.src = image.data;
        imageUrl.target.title = null;
        reload = true;
      }

      if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
        // Create an empty canvas element
        const canvas = document.createElement("canvas");
        canvas.width = imageUrl.target.naturalWidth;
        canvas.height = imageUrl.target.naturalHeight;

        // Copy the image contents to the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imageUrl.target, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const currentImageValues = handleInitialMapping(imageData);
        const newImageData = handlePixelTransform(imageData, currentImageValues);
        applyGaussianBlur(imageData);
        ctx.putImageData(newImageData, 0, 0);
        imageUrl.target.title = PALETTE_APPLIED_TITLE;
        imageUrl.target.src = canvas.toDataURL();
      }  
  };

  return (
    <ContentContainer>
      <HeaderTextContainer>
        <HeaderTitle>Captura da imagem</HeaderTitle>
        <HeaderDivider />
        <HeaderSubtitle>{"Câmera:"}</HeaderSubtitle>
        <InfoCardContainer>{generalData?.cameraMake || "-"}</InfoCardContainer>
      </HeaderTextContainer>
      <ImageCaptureSection>
        <ImageCaptureContainer>
          <ImageCapture src={generalData?.visibleImage || DefaultUser} />
        </ImageCaptureContainer>
        <ImageCaptureContainer>
          <ImageCapture 
            src={generalData?.thermalImage || DefaultUser}
            onLoad={generalData?.thermalImage ? (event) => getTransformedImage(event) : null}
          />
        </ImageCaptureContainer>
        <ImageCaptureContainer>
          <ImageCaptureInfoContainer>
            <DataContainer>
              <BodyHeaderText>Data e Hora</BodyHeaderText>
              <InfoCardContainer>
                {moment.parseZone(selectedItem.period).format("DD/MM/YYYY - HH:mm")}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Identificador</BodyHeaderText>
              <InfoCardContainer>
                {selectedItem.identifier ? selectedItem.identifier : "Sem ID"}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Nome</BodyHeaderText>
              <InfoCardContainer>
                {selectedItem.patientName ? selectedItem.patientName : ""}
              </InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Dispositivo</BodyHeaderText>
              <InfoCardContainer>{selectedItem.device}</InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>ID Análise</BodyHeaderText>
              <InfoCardContainer>{selectedItem.screeningId}</InfoCardContainer>
            </DataContainer>

            <DataContainer>
              <BodyHeaderText>Resultado</BodyHeaderText>
              <ResultText result={selectedItem.result} />
            </DataContainer>
          </ImageCaptureInfoContainer>
        </ImageCaptureContainer>
      </ImageCaptureSection>

      {/* General Info */}

      <GeneralInformationBody>
        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Temperatura Corporal</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Média</ItemText>
                <InfoCardContainer>
                  {generalData?.bodyTemperature || ""}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>

          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Temperatura de Referência</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Câmera</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.camera || ""}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Termopar</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.thermocouple || ""}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Offset</ItemText>
                <InfoCardContainer>
                  {generalData?.referenceTemperature?.offset || ""}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Grupos de imagens e diagnósticos</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.diagnosticGroups?.length !== 0 ? (
                generalData?.diagnosticGroups?.map((group, index) => (
                  <GroupDataContainer key={`group-${index}`}>
                    <InfoCardContainer
                      isGroup
                      isActivated={group.groupStatus === 1}
                      onClick={() => handleSeeGroupByID(group.groupId)}
                    >
                      {group.groupName}
                      <FiExternalLink />
                    </InfoCardContainer>
                  </GroupDataContainer>
                ))
              ) : (
                <EmptyContainer>Sem grupos.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>

          <GeneralInformationGroup>
            <BodyHeaderText>Laudo</BodyHeaderText>
            {generalData?.diagnosticReport ? (
              <GeneralInformationGroupContent>
                {generalData?.diagnosticReport?.reportStatus === 0 ? (
                  <EmptyContainer>
                    {reportStatusENUM.find(
                      (reportStatus) => reportStatus.id === 0
                    ).reportLabel || ""}
                  </EmptyContainer>
                ) : (
                  <GroupDataContainer laudo={true}>
                    <InfoCardContainer
                      isGroup={
                        generalData?.diagnosticReport?.reportStatus === 3
                          ? false
                          : true
                      }
                      onClick={() =>
                        handleReportClick({
                          reportStatus: generalData?.diagnosticReport?.reportStatus,
                          link: generalData?.diagnosticReport?.reportLink,
                        })
                      }
                      laudo={true}
                    >
                      {reportStatusENUM.find(
                        (reportStatus) =>
                          reportStatus.id ===
                            generalData?.diagnosticReport?.reportStatus
                      )?.reportLabel || ""}
                      <FiExternalLink />
                    </InfoCardContainer>
                    {generalData?.diagnosticReport?.reportStatus === 1 ? (
                      <InfoCardContainer
                        isGroup={true}
                        onClick={() =>
                          handleReportClick({
                            reportStatus: 2,
                            link: generalData?.diagnosticReport?.reportLink,
                            update: true
                          })
                        }
                        laudo={true}
                      >
                        {reportStatusENUM.find(
                          (reportStatus) =>
                            reportStatus.id ===
                              2
                        )?.reportLabel || ""}
                        <FiExternalLink />
                      </InfoCardContainer>
                    ): null}
                  </GroupDataContainer>
                )}
              </GeneralInformationGroupContent>
            ) : null}
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Sintomas respondidos</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.symptoms?.length !== 0 ? (
                generalData?.symptoms?.map((symptom, index) => (
                  <GroupDataContainer key={`symptom-${index}`}>
                    <InfoCardContainer>{symptom}</InfoCardContainer>
                  </GroupDataContainer>
                ))
              ) : (
                <EmptyContainer>Sem sintomas.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Morfometria</BodyHeaderText>
            <GeneralInformationGroupContent>
              <GroupDataContainer>
                <ItemText>Sexo</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.gender}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Idade</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.age}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Peso</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.weight}
                </InfoCardContainer>
              </GroupDataContainer>

              <GroupDataContainer>
                <ItemText>Altura</ItemText>
                <InfoCardContainer>
                  {generalData?.morphometry?.height}
                </InfoCardContainer>
              </GroupDataContainer>
            </GeneralInformationGroupContent>
          </GeneralInformationGroup>
        </GeneralInformationRow>

        <GeneralInformationRow>
          {/* Group */}
          <GeneralInformationGroup>
            <BodyHeaderText>Formulário</BodyHeaderText>
            <GeneralInformationGroupContent>
              {generalData?.form?.length !== 0 ? (
                generalData?.form?.map((formItem, index) => (
                  formItem.name !== 'visualDiagramMetadataImageURL' &&
                  formItem.name !== 'visualDiagramMetadataMarkdownColor' &&
                  formItem.name !== 'visualDiagramMetadataPointsArray' ? (
                    <GroupDataContainer key={`form-${index}`}>
                      <FormSection formItem={formItem} selectedItem={selectedItem} />
                    </GroupDataContainer>
                  ): null
                ))
              ) : (
                <EmptyContainer>Sem respostas de formulário.</EmptyContainer>
              )}
            </GeneralInformationGroupContent>
            {metadataPointsArray.length > 0 && (
              <KonvaImagePoints color={metadataColor} url={metadataImageUrl} points={metadataPointsArray} />
            )}
          </GeneralInformationGroup>
        </GeneralInformationRow>
      </GeneralInformationBody>
      {showModalAlert ? (
        <ModalAlert 
          setShowModal={setShowModalAlert} 
          message={'A análise já tem um laudo vinculado e será sobrescrito.'} />
      ) : null}
    </ContentContainer>
  );
}

export default BodySection;
