/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

// Libs
import { Stage, Layer, Rect } from "react-konva";
import Konva from "konva";
import {
    IoTriangleOutline,
    IoSquareOutline,
    IoRadioButtonOff
} from "react-icons/io5";

// Components
import SelectPalette from "./components/SelectPalette";
import RemoveImage from "./components/RemoveImage";
import DownloadImage from "./components/DownloadImage";
import InfoButton from "./components/InfoButton";
import ImageTransformer from "./components/ImageTransformer";
import DeltaCalculation from "./components/DeltaCalculation";
import Button from "../Button";
import ButtonToggle from "../ButtonToggle";

// Constants
import {
    defaultPaletteValue,
    paletteStageWidth,
    paletteMargin,
    tempStageWidth,
    imageTextHeight,
    defaultVirtualCanvasWidth,
    defaultVirtualCanvasHeight,
} from "./constants";

import {
    Container,
    TempContainer,
    StyledSlider,
    Track,
    Thumb,
    SliderContainer,
    ToolsContainer,
    NoImageText,
    SavedImageContainer,
    SavedImageElement,
    DeltaBtnWrapper,
    CleanDeltaTextBtn,
    ContainerButtonToggle,
    ContainerFooter,
    Wrapper,
    ContainerKonva,
    WrapperKonva
} from "./styles";
import { TextEditable } from "./components/TextEditable";

Konva.pixelRatio = 1;

function ThermalManipulation({
    trRef,
    paletteOptions,
    imagesOnCanvas = [],
    imagesTemperaturesOnCanvas = [],
    savedImage,
    isViewingSavedImage,
    handleSaveManipulation,
    isShowingDeltaCalcMenu,
    clearDelta,
    selectedPaletteMenu,
    formRectMenu,
    isSelectedFormMenu,
    setIsShowingDelta,
    setClearDelta,
    currentRangeValue,
    userSelectedRangeValue,
    onUserSelectedRangeValue,
    onClear,
    onRemoveImageOnIndex,
    onMoveImageToTop
}) {
    const stageImageRef = useRef();

    const [allImagesValues, setAllImagesValues] = useState([]);
    const [selectedPalette, setSelectedPalette] = useState(defaultPaletteValue);
    const [customIntervals, setCustomIntervals] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [stageScale, setStageScale] = useState(1);
    const [formRect, setFormRect] = useState("circle");
    const [isSelectedForm, setIsSelectedForm] = useState(false);
    const [isShowingDeltaCalc, setIsShowingDeltaCalc] = useState(false);
    const [isCanvaClearedOut, setIsCanvaClearedOut] = useState(false);

    // Delta capture
    const [firstAreaProps, setFirstAreaProps] = useState(null);
    const [secondAreaProps, setSecondAreaProps] = useState(null);
    const [firstAreaMaxTemperature, setFirstAreaMaxTemperature] = useState(0);
    const [firstAreaMinTemperature, setFirstAreaMinTemperature] = useState(0);
    const [firstAreaAvgTemperature, setFirstAreaAvgTemperature] = useState(0);
    const [secondAreaMaxTemperature, setSecondAreaMaxTemperature] = useState(0);
    const [secondAreaMinTemperature, setSecondAreaMinTemperature] = useState(0);
    const [secondAreaAvgTemperature, setSecondAreaAvgTemperature] = useState(0);
    const [clearSelected, setClearSelected] = useState(false);

    //Text states
    const [text, setText] = useState("Dois clicks para editar");
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(40);
    const [selected, setSelected] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    // Image download
    const [canvasDownloadPosition, setCanvasDownloadPosition] = useState([]);

    const [nodesArray, setNodes] = React.useState([]);
    const layerRef = React.useRef();

    useEffect(() => {
        if (clearDelta) {
            setFirstAreaMaxTemperature(0);
            setSecondAreaMaxTemperature(0);
        }
    }, [clearDelta]);

    useEffect(() => {
        if (selectedPaletteMenu) {
            setSelectedPalette(selectedPaletteMenu);
        }
    }, [selectedPaletteMenu]);

    useEffect(() => {
        if (formRectMenu) {
            setFormRect(formRectMenu);
        }
    }, [formRectMenu]);

    useEffect(() => {
        if (isSelectedFormMenu) {
            setIsSelectedForm(isSelectedFormMenu);
        }
    }, [isSelectedFormMenu]);

    useEffect(() => {
        if (isViewingSavedImage) {
            handleClearImageEdition();
        }
    }, [isViewingSavedImage]);

    useEffect(() => {
        if (allImagesValues.length) {
            handleCustomIntervals();
        }
    }, [allImagesValues]);

    useEffect(() => {
        handleCustomIntervals();
    }, [selectedPalette, userSelectedRangeValue]);

    useEffect(() => {
        handleWindowResize();

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleWindowResize = () => {
        const width = window.innerWidth - 60;
        const virtualCanvasWidth = defaultVirtualCanvasWidth();

        if (width < 600) {
            return;
        }

        const maxSize = width > virtualCanvasWidth ? virtualCanvasWidth : width;

        const scale = maxSize / virtualCanvasWidth;

        setStageScale(scale);
    };

    const createImageElement = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl || "";
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    };

    // Manipula a criação da imagem final a ser gerada:
    // (area principal do canvas + paleta + distribuição de temperatura)
    const handleFinalImage = async () => {
        const imageKeys = imagesOnCanvas.map(item => item.imageKey);
        const filteredCanvasDownloadPosition = canvasDownloadPosition.filter(item => imageKeys.includes(item.imageKey));
        const finalCanvasY = Math.min(...filteredCanvasDownloadPosition.map(item => item.minY));
        const finalCanvasHeight = Math.max(...filteredCanvasDownloadPosition.map(item => item.minHeight)) - finalCanvasY

        if (finalCanvasHeight > 0) {
            const stageImage = stageImageRef.current.toDataURL({ x: 0, y: finalCanvasY, width: defaultVirtualCanvasWidth(), height: finalCanvasHeight });

            const canvaElement = document.createElement("canvas");
            canvaElement.setAttribute("id", "virtual-canva");
            canvaElement.style.display = "none";
            document.body.appendChild(canvaElement);

            const finalCanvasHeightOffset = 0;

            const stage = new Konva.Stage({
                container: `virtual-canva`,
                width: defaultVirtualCanvasWidth() + paletteStageWidth + tempStageWidth,
                height: finalCanvasHeight + finalCanvasHeightOffset
            });

            const layer = new Konva.Layer();

            await createImageElement(stageImage).then((image) => {
                layer.add(
                    new Konva.Image({
                        image,
                        width: defaultVirtualCanvasWidth(),
                        height: finalCanvasHeight + finalCanvasHeightOffset
                    })
                );
            });

            layer.add(
                new Konva.Rect({
                    fillLinearGradientStartPoint: { x: 0, y: paletteStageWidth },
                    fillLinearGradientEndPoint: {
                        x: 0,
                        y: finalCanvasHeight + finalCanvasHeightOffset,
                    },
                    fillLinearGradientColorStops: handleNormalizedPalette(
                        selectedPalette?.palleteRgb
                    ),
                    width: paletteStageWidth,
                    height: finalCanvasHeight + finalCanvasHeightOffset,
                    x: defaultVirtualCanvasWidth(),
                })
            );

            layer.add(
                new Konva.Rect({
                    fill: "rgba(255, 255, 255, 1)",
                    width: tempStageWidth,
                    height: finalCanvasHeight + finalCanvasHeightOffset,
                    x: defaultVirtualCanvasWidth() + paletteStageWidth,
                })
            );

            const handleTextDistribution = (index) => {
                const totalHeight = finalCanvasHeight + finalCanvasHeightOffset;
                const halfHeight = totalHeight / 2;
                const halfImageTextHeight = imageTextHeight / 2;

                switch (index) {
                    case 0:
                        return 0;

                    case 1:
                        return halfHeight / 2 - halfImageTextHeight;

                    case 2:
                        return halfHeight - halfImageTextHeight;

                    case 3:
                        return totalHeight - halfHeight / 2 - halfImageTextHeight;

                    case 4:
                        return totalHeight - imageTextHeight;

                    default:
                        return 0;
                }
            };

            // eslint-disable-next-line array-callback-return
            customIntervals.map((interval, index) => {
                layer.add(
                    new Konva.Text({
                        text: interval ? interval.toFixed(2) : "",
                        fontSize: imageTextHeight,
                        fill: "#000000",
                        height: imageTextHeight,
                        x: defaultVirtualCanvasWidth() + paletteStageWidth + 5,
                        y: handleTextDistribution(index),
                    })
                );
            });

            stage.add(layer);

            const finalStageImage = stage.toDataURL();

            document.body.removeChild(canvaElement);

            return finalStageImage;
        }
    };

    const handleNormalizedRGB = (arrayRGB) => {
        const red = arrayRGB[0];
        const green = arrayRGB[1];
        const blue = arrayRGB[2];

        return `rgb(${red},${green},${blue})`;
    };

    const handleNormalizedPalette = (palette) => {
        let arrayColorStops = [];

        for (var i = 0; i < palette.length; i++) {
            arrayColorStops.push(
                i / (palette.length - 1),
                handleNormalizedRGB(palette[i])
            );
        }

        return arrayColorStops;
    };

    const handleCustomIntervals = () => {
        // const currentMinTemp = currentRangeValue[0];
        // const currentMaxTemp = currentRangeValue[1];

        const currentMinTemp = userSelectedRangeValue[0];
        const currentMaxTemp = userSelectedRangeValue[1];

        const intervalValue = currentMaxTemp - currentMinTemp;

        const intervalParcel = intervalValue / 5;

        setCustomIntervals([
            currentMaxTemp,
            currentMinTemp + intervalParcel * 3,
            currentMinTemp + intervalParcel * 2,
            currentMinTemp + intervalParcel,
            currentMinTemp,
        ]);
    };

    const handleClearImageEdition = () => {
        onClear();
        setAllImagesValues([]);
        setCanvasDownloadPosition([]);
    };

    const handleClearCanva = () => {
        handleClearImageEdition();
        handleClearDelta();
        setIsShowingDelta(false);
        setClearDelta(true);
        setIsCanvaClearedOut(true);
        setCanvasDownloadPosition([]);
    };

    const handleClearDelta = () => {        
        setIsShowingDeltaCalc(false);
        setFirstAreaMaxTemperature(0);
        setSecondAreaMaxTemperature(0);
    };

    const handleOnClickSave = async () => {
        handleBeforeRemoveImage();
        const finalImage = await handleFinalImage();

        handleSaveManipulation(finalImage, selectedPalette.name);
        setIsCanvaClearedOut(false);
    };

    const handleBeforeRemoveImage = () => {
        setSelectedImage(null);
        if (trRef.current) {
            trRef.current.nodes([]);
        }
    };

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();

        if (clickedOnEmpty) {
            setSelectedImage(null);
            trRef.current.nodes([]);
            setNodes([]);
        }
    };
    
    const selectionRectRef = React.useRef();
    const selection = React.useRef({
        visible: false,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
    });

    const updateSelectionRect = () => {
        const node = selectionRectRef.current;
        node.setAttrs({
            visible: selection.current.visible,
            x: Math.min(selection.current.x1, selection.current.x2),
            y: Math.min(selection.current.y1, selection.current.y2),
            width: Math.abs(selection.current.x1 - selection.current.x2),
            height: Math.abs(selection.current.y1 - selection.current.y2),
            fill: "rgba(0, 161, 255, 0.3)"
        });
        node.getLayer().batchDraw();
    };

    const oldPos = React.useRef(null);
    const onMouseDown = (e) => {
        const isElement = e.target.findAncestor(".elements-container");
        const isTransformer = e.target.findAncestor("Transformer");
        
        if (isElement || isTransformer) {
            return;
        }
    
        const pos = e.target.getStage().getPointerPosition();
        selection.current.visible = true;
        selection.current.x1 = pos.x;
        selection.current.y1 = pos.y;
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;
        updateSelectionRect();
    };
    
    const onMouseMove = (e) => {
        if (!selection.current.visible) {
            return;
        }
        const pos = e.target.getStage().getPointerPosition();
        selection.current.x2 = pos.x;
        selection.current.y2 = pos.y;
        updateSelectionRect();
    };

    const onMouseUp = () => {
        oldPos.current = null;
        if (!selection.current.visible) {
            return;
        }
        const selBox = selectionRectRef.current.getClientRect();

        const elements = [];
        layerRef.current.find(".image").forEach((elementNode) => {
            const elBox = elementNode.getClientRect();

            if (Konva.Util.haveIntersection(selBox, elBox)) {
                elements.push(elementNode);
            }
        });
        if (trRef.current) trRef.current.nodes(elements);
        selection.current.visible = false;
        // disable click event
        Konva.listenClickTap = false;
        updateSelectionRect();
    };
    
    const onClickTap = (e) => {
        // if we are selecting with rect, do nothing
        let stage = e.target.getStage();
        let layer = layerRef.current;
        let tr = trRef.current;
        // if click on empty area - remove all selections
        if (e.target === stage) {
            setSelectedImage(null);
            setNodes([]);
            tr.nodes([]);
            layer.draw();
            return;
        }
    
        // do nothing if clicked NOT on our rectangles
        if (!e.target.hasName(".image")) {
          return;
        }
    
        // do we pressed shift or ctrl?
        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = tr.nodes().indexOf(e.target) >= 0;
    
        if (!metaPressed && !isSelected) {
          // if no key pressed and the node is not selected
          // select just one
          tr.nodes([e.target]);
        } else if (metaPressed && isSelected) {
          // if we pressed keys and node was selected
          // we need to remove it from selection:
          const nodes = tr.nodes().slice(); // use slice to have new copy of array
          // remove node from array
          nodes.splice(nodes.indexOf(e.target), 1);
          tr.nodes(nodes);
        } else if (metaPressed && !isSelected) {
          // add the node into selection
          const nodes = tr.nodes().concat([e.target]);
          tr.nodes(nodes);
        }
        layer.draw();
    };

    const handleDragStart = (e) => {
        if (trRef.current && trRef.current.nodes() && trRef.current.nodes().length <= 1) {
            const items = imagesOnCanvas.slice();
            const item = items.find((i) => i.imageKey === e.target.id());
            const index = items.indexOf(item);
            onMoveImageToTop(index)
        }
    };

    const konvaRef = useRef();

    return (
        <Container>
            {isViewingSavedImage ? (
                <SavedImageContainer>
                    <SavedImageElement src={savedImage?.thermalImageLink} />
                </SavedImageContainer>
            ) : imagesOnCanvas.length || isCanvaClearedOut ? (
                <>
                    <ContainerKonva>
                        <WrapperKonva ref={konvaRef}>
                            <Stage
                                width={konvaRef.current && konvaRef.current.clientWidth - 2}
                                height={konvaRef.current && konvaRef.current.offsetHeight}
                                ref={stageImageRef}
                                onMouseDown={onMouseDown}
                                onMouseUp={onMouseUp}
                                onMouseMove={onMouseMove}
                                onTouchStart={checkDeselect}
                                onClick={onClickTap}
                            >
                                <Layer>
                                    <Rect
                                        key={konvaRef.current && konvaRef.current.offsetWidth}
                                        fill="#FFFFFF"
                                        width={defaultVirtualCanvasWidth()}
                                        height={defaultVirtualCanvasHeight()}
                                        onClick={() => setSelectedImage(null)}
                                        onTap={() => setSelectedImage(null)}
                                    />
                                </Layer>
                                <Layer ref={layerRef}>
                                    {isEditing && (
                                        <TextEditable
                                            x={stageScale}
                                            y={stageScale}
                                            text={text}
                                            onTextChange={(value) => setText(value)}
                                            width={width}
                                            height={height}
                                            selected={selected}
                                            onTextResize={(newWidth, newHeight) => {
                                                setWidth(newWidth);
                                                setHeight(newHeight);
                                            }}
                                            onClick={() => {
                                                setSelected(!selected);
                                            }}
                                            onTextClick={(newSelected) => {
                                                setSelected(newSelected);
                                            }}
                                        />
                                    )}

                                    {imagesOnCanvas.map((image, index) => (
                                        <ImageTransformer
                                            key={image.imageKey}
                                            image={image}
                                            temperatures={imagesTemperaturesOnCanvas[index]}
                                            selectedPalette={selectedPalette}
                                            currentRangeValue={currentRangeValue}
                                            allImagesValues={allImagesValues}
                                            setAllImagesValues={setAllImagesValues}
                                            isSelected={selectedImage === image.imageKey}
                                            stageImageRef={stageImageRef}
                                            firstAreaProps={firstAreaProps}
                                            secondAreaProps={secondAreaProps}
                                            setFirstAreaMaxTemperature={setFirstAreaMaxTemperature}
                                            setFirstAreaMinTemperature={setFirstAreaMinTemperature}
                                            setFirstAreaAvgTemperature={setFirstAreaAvgTemperature}
                                            setSecondAreaMaxTemperature={setSecondAreaMaxTemperature}
                                            setSecondAreaMinTemperature={setSecondAreaMinTemperature}
                                            setSecondAreaAvgTemperature={setSecondAreaAvgTemperature}
                                            transformerRef={trRef}
                                            onSelect={(e) => {
                                                if (e.currentTarget !== undefined) {
                                                  let temp = nodesArray;
                                                  if (!nodesArray.includes(e.currentTarget)) temp.push(e.currentTarget);
                                                  setNodes(temp);
                                                  
                                                  if (trRef.current) {
                                                    trRef.current.nodes(nodesArray);
                                                    trRef.current.getLayer().batchDraw();
                                                  }
                                                }
                                                setSelectedImage(image.imageKey);
                                            }}
                                            onImageDrag={handleDragStart}
                                            canvasDownloadPosition={canvasDownloadPosition}
                                            setCanvasDownloadPosition={setCanvasDownloadPosition}
                                        />
                                    ))}
                                    <Rect ref={selectionRectRef} />
                                </Layer>
                                {isShowingDeltaCalcMenu ? (
                                    <DeltaCalculation
                                        setFirstAreaProps={setFirstAreaProps}
                                        setSecondAreaProps={setSecondAreaProps}
                                        clearSelected={clearSelected}
                                        firstAreaMaxTemperature={firstAreaMaxTemperature}
                                        setFirstAreaMaxTemperature={setFirstAreaMaxTemperature}
                                        firstAreaMinTemperature={firstAreaMinTemperature}
                                        setFirstAreaMinTemperature={setFirstAreaMinTemperature}
                                        firstAreaAvgTemperature={firstAreaAvgTemperature}
                                        setFirstAreaAvgTemperature={setFirstAreaAvgTemperature}
                                        secondAreaMaxTemperature={secondAreaMaxTemperature}
                                        setSecondAreaMaxTemperature={setSecondAreaMaxTemperature}
                                        secondAreaMinTemperature={secondAreaMinTemperature}
                                        setSecondAreaMinTemperature={setSecondAreaMinTemperature}
                                        secondAreaAvgTemperature={secondAreaAvgTemperature}
                                        setSecondAreaAvgTemperature={setSecondAreaAvgTemperature}
                                        formRect={formRect}
                                    />
                                ) : null}
                            </Stage>
                        </WrapperKonva>
                        <Wrapper>
                            <Stage
                                width={paletteStageWidth}
                                height={konvaRef.current && konvaRef.current.offsetHeight}
                                style={{ border: "solid 1px", marginLeft: paletteMargin, height: '100%' }}                                
                            >
                                <Layer>
                                    <Rect
                                        fillLinearGradientStartPoint={{ x: 0, y: paletteStageWidth }}
                                        fillLinearGradientEndPoint={{
                                            x: 0,
                                            y: (defaultVirtualCanvasHeight() - 190) * stageScale,
                                        }}
                                        fillLinearGradientColorStops={handleNormalizedPalette(
                                            selectedPalette?.palleteRgb
                                        )}
                                        width={paletteStageWidth}
                                        height={konvaRef.current && konvaRef.current.offsetHeight}
                                    />
                                </Layer>
                            </Stage>
                            <TempContainer>
                                {customIntervals.map((interval, index) => (
                                    <p key={index}>{interval ? interval.toFixed(2) : interval}</p>
                                ))}
                            </TempContainer>
                        </Wrapper>
                        <SliderContainer>
                            <StyledSlider
                                key={konvaRef.current && konvaRef.current.offsetWidth}
                                renderTrack={Track}
                                renderThumb={Thumb}
                                value={userSelectedRangeValue}
                                // onChange={(value) => setCurrentRangeValue(value)}
                                onAfterChange={(value) => onUserSelectedRangeValue(value)}
                                step={0.01}
                                minDistance={0.1}
                                defaultValue={[currentRangeValue[0], currentRangeValue[1]]}
                                min={currentRangeValue[0]}
                                max={currentRangeValue[1]}
                                disabled={selectedPalette?.palleteRgb.length === 0}
                            />
                        </SliderContainer>

                        <ContainerFooter customWidth={defaultVirtualCanvasWidth()}>
                            <DownloadImage
                                handleFinalImage={handleFinalImage}
                                disabled={false}
                                setSelectedImage={setSelectedImage}
                                savedImage={savedImage}
                                isViewingSaveemotee={isViewingSavedImage}
                            />
                            {isViewingSavedImage ? null : (
                                <RemoveImage
                                    disabled={!imagesOnCanvas.length || !selectedImage}
                                    selectedImage={selectedImage}
                                    setBeforeRemove={handleBeforeRemoveImage}
                                    imagesOnCanvas={imagesOnCanvas}
                                    onRemoveImageOnIndex={onRemoveImageOnIndex}
                                />
                            )}
                            {!handleSaveManipulation || isViewingSavedImage ? null : (
                                <Button
                                    label="Limpar"
                                    onClick={handleClearCanva}
                                    disabled={!imagesOnCanvas.length}
                                />
                            )}
                            {!handleSaveManipulation || isViewingSavedImage ? null : (
                                <Button
                                    label="Salvar análise"
                                    onClick={() => {
                                        setClearSelected(true);
                                        setSelectedImage(null);
                                        setTimeout(() => {
                                            handleOnClickSave();
                                        }, 0);
                                    }}
                                    disabled={!imagesOnCanvas.length}
                                />
                            )}
                            {!handleSaveManipulation || isViewingSavedImage ? null : <InfoButton />}
                        </ContainerFooter>
                    </ContainerKonva>
                </>
            ) : (
                <NoImageText>
                    Selecione ao menos uma imagem térmica para iniciar a análise.
                </NoImageText>
            )
            }
            <ToolsContainer>
                <SelectPalette
                    label="Paleta"
                    options={paletteOptions}
                    defaultPaletteValue={defaultPaletteValue}
                    selectedPalette={selectedPalette}
                    setSelectedPalette={setSelectedPalette}
                    disabled={!imagesOnCanvas.length}
                    savedImage={savedImage}
                    isViewingSavedImage={isViewingSavedImage}
                    isGalleryLeft={false}
                />

                {!handleSaveManipulation || isViewingSavedImage ? null : (
                    <DeltaBtnWrapper isGalleryLeft={false}>
                        <Button
                            onClick={() => setIsShowingDeltaCalc(true)}
                            disabled={!imagesOnCanvas.length}
                            icon={<IoTriangleOutline size={24} />}
                        />
                        {isShowingDeltaCalc ? (
                            <CleanDeltaTextBtn
                                onClick={handleClearDelta}
                            >
                                Limpar Delta
                            </CleanDeltaTextBtn>
                        ) : null}
                        <ContainerButtonToggle>
                            <ButtonToggle
                                onClick={() => {
                                    setFormRect('circle');
                                    setIsSelectedForm(!isSelectedForm)
                                }}
                                icon={<IoRadioButtonOff size={20} />}
                                selected={formRect === 'circle' ? true : false}
                            />
                            <ButtonToggle
                                onClick={() => {
                                    setFormRect('square');
                                    setIsSelectedForm(!isSelectedForm)
                                }}
                                icon={<IoSquareOutline size={20} />}
                                selected={formRect === 'square' ? true : false}
                            />
                        </ContainerButtonToggle>
                    </DeltaBtnWrapper>
                )}
            </ToolsContainer>
        </Container >
    );
}

export default ThermalManipulation;
