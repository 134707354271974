import React, { useRef, useState } from "react";

// Libs
import { MdAddCircle } from "react-icons/md";
import { BiExpand } from "react-icons/bi";

//Icons
import DoubleArrow from "assets/double-arrow.svg";
import GaleryIcon from "assets/gallery.svg"
import Engine from "assets/engine.svg"

import { IoTriangleOutline, IoSquareOutline, IoRadioButtonOff } from "react-icons/io5";

// Imgs
import DefaultUser from "assets/default-user.png";

//Components
import SelectPalette from "../../../../../../../../components/ThermalManipulation/components/SelectPalette";
import ButtonToggle from "../../../../../../../../components/ButtonToggle";
import { DropDown } from "components/DropDown";

// Services
import DashboardService from "services/dashboard";

// Context
import { useGeneralProvider } from "contexts/general";

// Utils
import { getDefaultPalleteRgb, applyGaussianBlur } from "utils";

// Constants
import {
    minTemperatureCelsius,
    maxTemperatureCapCelsius
} from "../../../../../../../../components/ThermalManipulation/constants";


import {
    Container,
    GalleryText,
    ToolsText,
    GalleryContainer,
    GalleryImageContainer,
    GalleryImage,
    ExpandContainer,
    Header,
    ToogleDrawer,
    ActionIcon,
    DeltaBtnWrapper,
    CleanDeltaTextBtn,
    ContainerButtonToggle,
    ActionIconLeft,
    Item,
    Options,
    TitleDropDown,
    FlexTitle,
    BackDropDown,
    RemovePallete
} from "./styles";

const PALETTE_APPLIED_TITLE = 'Pallete applied';

function Gallery({
    handleImageClick,
    groupImages,
    handleGetImageByID,
    handleGetAnalysedImageInfo,
    addedImagesIds,
    setSavedImage,
    setIsViewingSavedImage,
    setShowModalConfirm,
    groupAnalysedImages,
    toggleLeft,
    setIsShowingDeltaCalc,
    isShowingDeltaCalc,
    setClearDelta,
    setFormRect,
    formRect,
    setIsSelectedForm,
    isSelectedForm,
    pallete,
    paletteOptions,
    selectedPalette,
    setSelectedPalette,
    isGalleryLeft
}) {
    const palleteRgb = getDefaultPalleteRgb();
    const { _, globalUser } = useGeneralProvider();
    const userPassService = new DashboardService(globalUser);

    const [deltaActive, setDeltaActive] = useState(false);

    const [showDrop, setShowDrop] = useState(false)

    const [anchorEl, setAnchorEl] = useState()

    const [itemsDropDown, setItemsDropDown] = useState()

    const [titleDrop, setTitleDrop] = useState()

    const itemsRef = useRef()

    itemsRef.current = []

    const handleRef = (ref) => {
        if (ref && !itemsRef.current.includes(ref)) {
            itemsRef.current.push(ref)
        }
    }

    const addImageToKonva = (imageId, isAnalysed) => {
        setDeltaActive(true);

        setIsViewingSavedImage(false);

        handleImageClick({
            imageUrl: handleGetImageByID(imageId, isAnalysed),
            imageId: imageId,
            isAnalysed: isAnalysed
        });
    };

    const itemsEngine = [
        {
            id: 1,
            label: 'Paleta',
            onClick: () => handleItemsPalette(),
        },
        {
            id: 2,
            label: 'Seleção',
            onClick: () => handleShapes(),
        },
        {
            id: 3,
            label: isShowingDeltaCalc ? 'Remover delta' : 'Delta',
            onClick: () => {
                setShowDrop(false)
                setIsShowingDeltaCalc(!isShowingDeltaCalc)
            },
        }
    ]

    if (selectedPalette) {
        if (selectedPalette.name !== '') {
            itemsEngine.push(
                {
                    id: 4,
                    label: 'Remover paleta',
                    onClick: () => {
                        setShowDrop(false)
                        removePallete()
                    },
                }
            )
        }
    }

    const handleItemsPalette = () => {
        let arrItems = []
        Array.from(paletteOptions)
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((x, index) => {
                arrItems.push({
                    label: x.name,
                    onClick: () => {
                        setShowDrop(false)
                        setSelectedPalette(x)
                    },
                    id: index
                })
            })

        backTitleDropDow()

        setItemsDropDown(arrItems)
    }



    const removePallete = () => {
        setSelectedPalette({ name: "", palleteRgb: [] })
    }


    const handleImages = () => {

        const arrImages = []

        groupImages.map((imageId, index) => {
            if (!addedImagesIds.includes(imageId)) {
                arrImages.push({
                    label: `Foto ${index + 1}`,
                    id: index,
                    onClick: () => {
                        setShowDrop(false)
                        addImageToKonva(imageId)
                    }
                })
            }
        })

        return arrImages
    }

    const backTitleDropDow = () => {
        setTitleDrop(
            <FlexTitle onClick={() => {
                setItemsDropDown(itemsEngine)
                setTitleDrop(itemsClosedMenu[0].title)
            }}>
                <BackDropDown src={DoubleArrow} />
                <TitleDropDown>Voltar</TitleDropDown>
            </FlexTitle>
        )
    }

    const handleShapes = () => {
        backTitleDropDow()
        setItemsDropDown([
            {
                label: 'Círculo',
                onClick: () => {
                    setShowDrop(false)
                    selectedForm('circle')
                },
                id: 1
            },
            {
                label: 'Retângulo',
                onClick: () => {
                    setShowDrop(false)
                    selectedForm('square')
                },
                id: 2
            }
        ])
    }

    const itemsClosedMenu = [
        {
            icon: Engine,
            ref: handleRef,
            title: <TitleDropDown>Ferramentas</TitleDropDown>,
            items: itemsEngine
        },
        {
            icon: GaleryIcon,
            ref: handleRef,
            title: <TitleDropDown>Galeria</TitleDropDown>,
            items: handleImages()
        }
    ]

    const selectedForm = (form) => {
        setFormRect(form);
        setIsSelectedForm(!isSelectedForm)
    }

    // Extrai o "raw" da imagem com base no RGB de cada pixel e calcula a temperatura
    const handleGetTemperature = (green, blue) => {
        const raw = green * 256 + blue;

        const M = 0.02569698246480104;
        const B = 64.74694198003411;

        const temp = M * raw - B;

        return temp;
    };

    const handleInitialMapping = (imageData) => {
        const dataLength = imageData.data.length;
        const pixelLength = 4;

        const temperaturePixelArray = [];

        for (let i = 0; i <= dataLength - pixelLength; i += pixelLength) {
            const green = imageData.data[i + 1];
            const blue = imageData.data[i + 2];

            const temp = handleGetTemperature(green, blue);

            if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
                temperaturePixelArray.push({ t: temp, p: i });
            }
        }

        const currentImageSortedData = temperaturePixelArray.sort((a, b) => {
            if (a.t < b.t) return -1;
            if (a.t > b.t) return 1;
            return 0;
        });

        return currentImageSortedData;
    };

    const handleTemperaturePerPixel = (imageData, currentImageValues) => {
        const temperaturePixelArray = [];

        for (let i = 0; i < currentImageValues.length; i++) {
            temperaturePixelArray.push(currentImageValues[i]);
        }
        return temperaturePixelArray;
    };

    const handlePixelTransform = (imageData, currentImageValues) => {
        const temperaturePixelArray = handleTemperaturePerPixel(imageData, currentImageValues);
        const expandedPalette = [];

        const pixelsPerColor = Math.ceil(
            temperaturePixelArray.length / palleteRgb.length
        );

        const reversedPalette = [...palleteRgb].reverse();

        for (let i = 0; i < reversedPalette.length; i++) {
            for (let j = 0; j < pixelsPerColor; j++) {
                expandedPalette.push(reversedPalette[i]);
            }
        }

        if (expandedPalette.length !== 0) {
            for (let i = 0; i < temperaturePixelArray.length; i++) {
                const currentColor = expandedPalette[i] || [0, 0, 0];

                // red
                imageData.data[temperaturePixelArray[i].p] = currentColor[0];
                // green
                imageData.data[temperaturePixelArray[i].p + 1] = currentColor[1];
                // blue
                imageData.data[temperaturePixelArray[i].p + 2] = currentColor[2];
            }
        }
        return imageData;
    };

    const validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const getTransformedImage = async (imageUrl) => {    
        let reload = false;
        if (validURL(imageUrl.target.currentSrc)) {
            const image = await userPassService.getImageBase64ByUrl(imageUrl.target.currentSrc);
            imageUrl.target.src = image.data;
            imageUrl.target.title = null;
            reload = true;
        }

        if (!reload && imageUrl.target.title !== PALETTE_APPLIED_TITLE) {
            // Create an empty canvas element
            const canvas = document.createElement("canvas");
            canvas.width = imageUrl.target.naturalWidth;
            canvas.height = imageUrl.target.naturalHeight;

            // Copy the image contents to the canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(imageUrl.target, 0, 0);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const currentImageValues = handleInitialMapping(imageData);
            const newImageData = handlePixelTransform(imageData, currentImageValues);
            applyGaussianBlur(imageData);
            ctx.putImageData(newImageData, 0, 0);
            imageUrl.target.title = PALETTE_APPLIED_TITLE;
            imageUrl.target.src = canvas.toDataURL();
        }  
    };

    return (
        <>
            <Container>

                <Header>

                    {isGalleryLeft && (
                        <>
                            <ToolsText>Ferramentas</ToolsText>
                            <ToogleDrawer
                                onClick={() => toggleLeft(false)}>
                                <ActionIcon src={DoubleArrow} />
                            </ToogleDrawer>
                        </>
                    )}

                    {!isGalleryLeft && (
                        <ToogleDrawer onClick={() => toggleLeft(true)}>
                            <ActionIconLeft src={DoubleArrow} />
                        </ToogleDrawer>
                    )}
                </Header>

                {isGalleryLeft && (
                    <>
                        <SelectPalette
                            label="Paleta"
                            options={paletteOptions}
                            selectedPalette={selectedPalette}
                            setSelectedPalette={setSelectedPalette}
                            isGalleryLeft={true}
                        />
                        {/* {selectedPalette && (selectedPalette && selectedPalette.name !== '') && (
                            <RemovePallete onClick={removePallete}>Remover paleta</RemovePallete>
                        )} */}
                        <DeltaBtnWrapper>
                            <ButtonToggle
                                onClick={() => {
                                    setIsShowingDeltaCalc(!isShowingDeltaCalc)
                                    setClearDelta(true)
                                }}
                                icon={<IoTriangleOutline size={24} />}
                                menuIsLeft={true}
                                selected={isShowingDeltaCalc ? true : false}
                            />
                            <ContainerButtonToggle>
                                <ButtonToggle
                                    onClick={() => {
                                        setFormRect('circle');
                                        setIsSelectedForm(!isSelectedForm)
                                    }}
                                    icon={<IoRadioButtonOff size={20} />}
                                    selected={formRect === 'circle' ? true : false}
                                />
                                <ButtonToggle
                                    onClick={() => {
                                        setFormRect('square');
                                        setIsSelectedForm(!isSelectedForm)
                                    }}
                                    icon={<IoSquareOutline size={20} />}
                                    selected={formRect === 'square' ? true : false}
                                />
                            </ContainerButtonToggle>
                        </DeltaBtnWrapper>

                        <GalleryText>Galeria</GalleryText>
                        <GalleryContainer>
                            {groupImages &&
                                groupImages.length !== 0 &&
                                groupImages.map(imageId => (
                                    <GalleryImageContainer
                                        key={imageId}
                                        onClick={() => addImageToKonva(imageId)}
                                    >
                                        <GalleryImage 
                                            src={handleGetImageByID(imageId) || DefaultUser}
                                            onLoad={getTransformedImage}
                                        />
                                        <ExpandContainer>
                                            <MdAddCircle />
                                        </ExpandContainer>
                                    </GalleryImageContainer>
                                ))}

                            {groupAnalysedImages &&
                                groupAnalysedImages.length !== 0 &&
                                groupAnalysedImages.map((imageId) => (
                                    <GalleryImageContainer
                                        key={imageId}
                                        onClick={() => addImageToKonva(imageId, true)}
                                    >
                                        <GalleryImage
                                            src={handleGetImageByID(imageId, true) || DefaultUser}
                                        />
                                        <ExpandContainer>
                                            <MdAddCircle />
                                        </ExpandContainer>
                                    </GalleryImageContainer>
                                ))}
                        </GalleryContainer>
                    </>
                )}

                {!isGalleryLeft && (
                    <Options>
                        {itemsClosedMenu.map((x, index) =>
                            <Item src={x.icon}
                                key={index}
                                ref={x.ref}
                                onClick={() => {
                                    setShowDrop(true)
                                    setAnchorEl(itemsRef.current[index])
                                    setItemsDropDown(x.items)
                                    setTitleDrop(x.title)
                                }}
                            />)}
                    </Options>
                )}

            </Container>

            <DropDown
                anchorEl={anchorEl}
                onClose={() => setShowDrop(false)}
                open={showDrop}
                title={titleDrop}
                anchorOrigin={{
                    horizontal: 40,
                    vertical: 0
                }}
                items={itemsDropDown}
            />
        </>
    );
}

export default Gallery;
