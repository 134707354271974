/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";

// Libs
import { Image as KonvaImage, Transformer } from "react-konva";

// Utils
import { applyGaussianBlur } from "utils";

// Constants
import {
  defaultVirtualCanvasWidth,
  defaultVirtualCanvasHeight,
  minTemperatureCelsius,
  maxTemperatureCapCelsius,
  paletteStageWidth,
  tempStageWidth
} from "../../constants";

function ImageTransformer({
  image,
  temperatures,
  isSelected,
  onSelect,
  stageImageRef,
  firstAreaProps,
  secondAreaProps,
  setFirstAreaMaxTemperature,
  setFirstAreaMinTemperature,
  setFirstAreaAvgTemperature,
  setSecondAreaMaxTemperature,
  setSecondAreaMinTemperature,
  setSecondAreaAvgTemperature,
  transformerRef,
  canvasDownloadPosition,
  setCanvasDownloadPosition,
  onImageDrag
}) {
  const [mappingIsDone, setMappingIsDone] = useState(false);
  const [imageInitialPosition, setImageInitialPosition] = useState({
    x: 0,
    y: 0,
  });

  const imageRef = useRef();

  useEffect(() => {
    if (image) {
      if (image.isAnalysed) {
        const imageWidth = imageRef.current.width();
        const imageHeight = imageRef.current.height();
        const baseScaleFactor = 0.5;

        imageRef.current.crop({
          x: 0,
          y: 0,
          width: imageWidth - paletteStageWidth - tempStageWidth,
          height: imageHeight
        });
        imageRef.current.setWidth(imageWidth * baseScaleFactor);
        imageRef.current.setHeight(imageHeight * baseScaleFactor);
      }
      imageRef.current.cache();
      handleImageInitialPosition();
    }
  }, [image]);

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  // "Escuta" o movimento da área 1
  useEffect(() => {
    handleCurrentAreaCalc(firstAreaProps, setFirstAreaMaxTemperature, setFirstAreaMinTemperature, setFirstAreaAvgTemperature);
  }, [firstAreaProps]);
  // "Escuta" o movimento da área 2
  useEffect(() => {
    handleCurrentAreaCalc(secondAreaProps, setSecondAreaMaxTemperature, setSecondAreaMinTemperature, setSecondAreaAvgTemperature);
  }, [secondAreaProps]);

  // Centraliza a lógica para considerarar o cálculo de captura da área
  const handleCurrentAreaCalc = (
    currentAreaProps,
    setCurrentAreaMaxTemperature,
    setCurrentAreaMinTemperature,
    setCurrentAreaAvgTemperature,
  ) => {
    if (
      currentAreaProps &&
      handleHaveIntersection(
        handleImageProps(imageRef.current),
        currentAreaProps
      )
    ) {
      handleCaptureAreaOfImageData(
        currentAreaProps,
        setCurrentAreaMaxTemperature,
        setCurrentAreaMinTemperature,
        setCurrentAreaAvgTemperature,
      );
    }
  };

  // Extrai propriedades pertinentes da imagem atual
  const handleImageProps = (currentImage) => {
    const x = currentImage?.x();
    const y = currentImage?.y();
    const width = currentImage?.width() * currentImage?.scaleX();
    const height = currentImage?.height() * currentImage?.scaleY();

    return { x, y, width, height };
  };

  // Verifica se uma determinada área de captura está sobre a imagem atual
  const handleHaveIntersection = (r1, r2) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  };

  // Adiciona a imagem ao centro do canvas
  const handleImageInitialPosition = () => {
    const imageDefaultWidth = imageRef.current.width();
    const imageDefaultHeight = imageRef.current.height();

    const xPosition = defaultVirtualCanvasWidth() / 2 - imageDefaultWidth / 2;
    const yPosition = defaultVirtualCanvasHeight() / 2 - imageDefaultHeight / 2;

    setImageInitialPosition({ x: xPosition, y: yPosition });
  };


  const getNewImageAbsolutePosition = (currentStage, currentImage) => {
    const box = currentImage.getClientRect();
    const absPos = currentImage.getAbsolutePosition();
    const offsetX = box.x - absPos.x;
    const offsetY = box.y - absPos.y;

    const newAbsPos = { ...absPos };
    if (box.x < 0) {
      newAbsPos.x = -offsetX;
    }
    if (box.y < 0) {
      newAbsPos.y = -offsetY;
    }
    if (box.x + box.width > currentStage.width()) {
      newAbsPos.x = currentStage.width() - box.width - offsetX;
    }
    if (box.y + box.height > currentStage.height()) {
      newAbsPos.y = currentStage.height() - box.height - offsetY;
    }
    return newAbsPos;
  };

  const handleTransformEnd = (stageRef, itemRef) => {
    const currentStage = stageRef.current;
    const currentImage = itemRef.current;

    const box = currentImage.getClientRect();    
    const newAbsPos = getNewImageAbsolutePosition(currentStage, currentImage);

    setCanvasDownloadPosition((prevState) => {
      const indexToUpdate = canvasDownloadPosition.findIndex(item => item.imageKey === currentImage.id());

      if (indexToUpdate > -1) {
        prevState[indexToUpdate].minHeight = newAbsPos.y + box.height;
        prevState[indexToUpdate].minY = newAbsPos.y;
        return prevState;
      }
      else {
        return [...prevState, { 
          imageKey: currentImage.id(), 
          minHeight: newAbsPos.y + box.height, 
          minY: newAbsPos.y 
        }];
      }
    });
  };

  // Verifica se uma imagem está arrastada para fora do canvas
  const handleDragOutOfCanvas = (stageRef, itemRef) => {
    const currentStage = stageRef.current;
    const currentImage = itemRef.current;
    
    const newAbsPos = getNewImageAbsolutePosition(currentStage, currentImage);
    currentImage.setAbsolutePosition(newAbsPos);

    const box = currentImage.getClientRect();
    
    setCanvasDownloadPosition((prevState) => {
      const indexToUpdate = canvasDownloadPosition.findIndex(item => item.imageKey === currentImage.id());

      if (indexToUpdate > -1) {
        prevState[indexToUpdate].minHeight = newAbsPos.y + box.height;
        prevState[indexToUpdate].minY = newAbsPos.y;
        return prevState;
      }
      else {
        return [...prevState, { 
          imageKey: currentImage.id(), 
          minHeight: newAbsPos.y + box.height, 
          minY: newAbsPos.y 
        }];
      }
    });
  };

  const extractArea = (matrix, xd, yd, width, height) => {
    const extractedValues = [];

    let x = parseInt(xd)
    let y = parseInt(yd)

    if (matrix != undefined) {
      for (let i = y; i < y + height && i < matrix.length; i++) {
        let positionValue = matrix[i];
        if (positionValue != undefined) {
          for (let j = x; j < x + width && j < positionValue.length; j++) {
              extractedValues.push(positionValue[j]);
          }
        }
      }
    }

    return extractedValues;
  };

  // Captura uma determinada porção da imagem com base
  // na área de captura, reverte para a escala original e
  // extrai a temperatura máxima dessa região
  const handleCaptureAreaOfImageData = (
    currentAreaProps,
    setCurrentAreaMaxTemperature,
    setCurrentAreaMinTemperature,
    setCurrentAreaAvgTemperature,
  ) => {
    const currentImage = imageRef.current;
    const imageX = currentImage.x();
    const imageY = currentImage.y();
    const imageWidth = currentImage.width();
    const imageHeight = currentImage.height();
    const imageScaleX = currentImage.scaleX();
    const imageScaleY = currentImage.scaleY();

    const scaleResetX = imageWidth / (imageWidth * imageScaleX);
    const scaleResetY = imageHeight / (imageHeight * imageScaleY);

    // Dados para a área de captura da imagem
    const captureAreaX = (currentAreaProps?.x - imageX) * scaleResetX;
    const captureAreaY = (currentAreaProps?.y - imageY) * scaleResetY;
    const captureAreaWidth = currentAreaProps?.width * scaleResetX;
    const captureAreaHeight = currentAreaProps?.height * scaleResetY;

    // Cria um canva virtual que representa a imagem original
    const canvaElement = document.createElement("canvas");
    canvaElement.style.display = "none";
    canvaElement.width = imageWidth;
    canvaElement.height = imageHeight;
    document.body.appendChild(canvaElement);

    const ctx = canvaElement.getContext("2d");
    ctx.drawImage(currentImage.image(), 0, 0, imageWidth, imageHeight);
    // Captura uma determinada área da imagem "virtual"
    const capturedImageData = ctx.getImageData(
      captureAreaX,
      captureAreaY,
      captureAreaWidth,
      captureAreaHeight
    );

    document.body.removeChild(canvaElement);

    const extractedArea = extractArea(temperatures.values, captureAreaX, captureAreaY, captureAreaWidth, captureAreaHeight)

    // Calcula temperatura da região capturada
    const areaTemperatureArray = [];

    for (let i = 0; i < extractedArea.length; i++) {
      const temp = extractedArea[i];

      if (temp >= minTemperatureCelsius && temp <= maxTemperatureCapCelsius) {
        areaTemperatureArray.push(temp);
      }
    }

    const areaMaxTemperature = Math.max(...areaTemperatureArray);
    const areaMinTemperature = Math.min(...areaTemperatureArray);

    const sum = areaTemperatureArray.reduce((a, b) => a + b, 0);
    const areaAvgTemperature = (sum / areaTemperatureArray.length) || 0;

    if (setCurrentAreaMaxTemperature) {
      setCurrentAreaMaxTemperature(areaMaxTemperature);
      setCurrentAreaMinTemperature(areaMinTemperature);
      setCurrentAreaAvgTemperature(areaAvgTemperature);
    }
  };

  return (
    <>
      <KonvaImage
        {...imageInitialPosition}
        ref={imageRef}
        onClick={onSelect}
        onTap={onSelect}
        image={image.imageData}
        draggable
        onDragMove={() => handleDragOutOfCanvas(stageImageRef, imageRef)}
        onDragStart={onImageDrag}
        onTransformEnd={() => handleTransformEnd(stageImageRef, imageRef)}
        id={image.imageKey}
        name="image"
        lineJoin="round"
      />    
      <Transformer
        rotateEnabled={true}
        ref={transformerRef}
        anchorSize={12}
        rotationSnaps={[0, 90, 180, 270]}
      />      
    </>
  );
}

export default ImageTransformer;
